.testimonials {
  &.active {
    .rfm-marquee-container div {
      animation-play-state: paused;
    }
  }

  .marquee-container {
    margin-top: 50px;

    .rfm-marquee {
      align-items: start;
    }

    .rfm-initial-child-container {
      align-items: start;
    }

    .rfm-child {
      border-radius: 40px;
      box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.25);
      margin-right: 1rem;
      overflow: hidden;
      user-select: none;
    }
  }
}