@font-face {
    font-family: "sharp-grotesk-book";
    src: local("SharpGrotesk-Book20"), url("./fonts/SharpGrotesk-Book20.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "sharp-grotesk-book";
    src: local("SharpGrotesk-BookItalic20"), url("./fonts/SharpGrotesk-BookItalic20.otf") format("opentype");
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: "sharp-grotesk-book25";
    src: local("SharpGrotesk-Book25"), url("./fonts/SharpGrotesk-Book25.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-Black20"), url("./fonts/SharpGrotesk-Black20.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-BlackItalic20"), url("./fonts/SharpGrotesk-BlackItalic20.otf") format("opentype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-Bold20"), url("./fonts/SharpGrotesk-Bold20.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-BoldItalic20"), url("./fonts/SharpGrotesk-BoldItalic20.otf") format("opentype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-SemiBold20"), url("./fonts/SharpGrotesk-SemiBold20.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-SemiBoldItalic20"), url("./fonts/SharpGrotesk-SemiBoldItalic20.otf") format("opentype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-Medium20"), url("./fonts/SharpGrotesk-Medium20.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-MediumItalic20"), url("./fonts/SharpGrotesk-MediumItalic20.otf") format("opentype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-Light20"), url("./fonts/SharpGrotesk-Light20.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-LightItalic20"), url("./fonts/SharpGrotesk-LightItalic20.otf") format("opentype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-Thin20"), url("./fonts/SharpGrotesk-Thin20.otf") format("opentype");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "sharp-grotesk";
    src: local("SharpGrotesk-ThinItalic20"), url("./fonts/SharpGrotesk-ThinItalic20.otf") format("opentype");
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: "sharp-grotesk25";
    src: local("SharpGrotesk-Light25"), url("./fonts/SharpGrotesk-Light25.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "sharp-grotesk25";
    src: local("SharpGrotesk-Thin25"), url("./fonts/SharpGrotesk-Thin25.otf") format("opentype");
    font-weight: 100;
    font-style: normal;
}