 .approach-animation svg .svg-elem-1 {
  stroke-dashoffset: 2264.247802734375px;
  stroke-dasharray: 2264.247802734375px;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}

.approach-animation.active svg .svg-elem-1 {
  stroke-dashoffset: 0;
}

.approach-animation svg .svg-elem-2 {
  stroke-dashoffset: 2314.278564453125px;
  stroke-dasharray: 2314.278564453125px;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
}

.approach-animation.active svg .svg-elem-2 {
  stroke-dashoffset: 0;
}

.approach-animation svg .svg-elem-3 {
  stroke-dashoffset: 2362.435791015625px;
  stroke-dasharray: 2362.435791015625px;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4s;
}

.approach-animation.active svg .svg-elem-3 {
  stroke-dashoffset: 0;
}

.approach-animation svg .svg-elem-4 {
  stroke-dashoffset: 121.38052083641213px;
  stroke-dasharray: 121.38052083641213px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8999999999999999s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8999999999999999s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7s;
}

.approach-animation.active svg .svg-elem-4 {
  stroke-dashoffset: 0;
  fill: rgb(154, 151, 255);
}

.approach-animation svg .svg-elem-5 {
  stroke-dashoffset: 248.99801635742188px;
  stroke-dasharray: 248.99801635742188px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
}

.approach-animation.active svg .svg-elem-5 {
  stroke-dashoffset: 0;
  fill: rgb(73, 73, 73);
}

.approach-animation svg .svg-elem-6 {
  stroke-dashoffset: 602.3556518554688px;
  stroke-dasharray: 602.3556518554688px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
}

.approach-animation.active svg .svg-elem-6 {
  stroke-dashoffset: 0;
  fill: rgb(0, 0, 0);
}

.approach-animation svg .svg-elem-7 {
  stroke-dashoffset: 1732.896728515625px;
  stroke-dasharray: 1732.896728515625px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5999999999999996s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5999999999999996s;
}

.approach-animation.active svg .svg-elem-7 {
  stroke-dashoffset: 0;
  fill: rgb(0, 0, 0);
}

.approach-animation svg .svg-elem-8 {
  stroke-dashoffset: 410.4656066894531px;
  stroke-dasharray: 410.4656066894531px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s;
}

.approach-animation.active svg .svg-elem-8 {
  stroke-dashoffset: 0;
  fill: rgb(105, 102, 255);
}

.approach-animation svg .svg-elem-9 {
  stroke-dashoffset: 244.8165283203125px;
  stroke-dasharray: 244.8165283203125px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s;
}

.approach-animation.active svg .svg-elem-9 {
  stroke-dashoffset: 0;
  fill: rgb(0, 0, 0);
}

.approach-animation svg .svg-elem-10 {
  stroke-dashoffset: 121.38052083641213px;
  stroke-dasharray: 121.38052083641213px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6999999999999997s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6999999999999997s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s;
}

.approach-animation.active svg .svg-elem-10 {
  stroke-dashoffset: 0;
  fill: rgb(186, 141, 245);
}

.approach-animation svg .svg-elem-11 {
  stroke-dashoffset: 249.001953125px;
  stroke-dasharray: 249.001953125px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.8s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.8s;
}

.approach-animation.active svg .svg-elem-11 {
  stroke-dashoffset: 0;
  fill: rgb(73, 73, 73);
}

.approach-animation svg .svg-elem-12 {
  stroke-dashoffset: 517.6630859375px;
  stroke-dasharray: 517.6630859375px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.1s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.1s;
}

.approach-animation.active svg .svg-elem-12 {
  stroke-dashoffset: 0;
  fill: rgb(0, 0, 0);
}

.approach-animation svg .svg-elem-13 {
  stroke-dashoffset: 1401.0462646484375px;
  stroke-dasharray: 1401.0462646484375px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5999999999999996s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.3999999999999995s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5999999999999996s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.3999999999999995s;
}

.approach-animation.active svg .svg-elem-13 {
  stroke-dashoffset: 0;
  fill: rgb(0, 0, 0);
}

.approach-animation svg .svg-elem-14 {
  stroke-dashoffset: 430.68231201171875px;
  stroke-dasharray: 430.68231201171875px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.9s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.7s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.9s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.7s;
}

.approach-animation.active svg .svg-elem-14 {
  stroke-dashoffset: 0;
  fill: rgb(145, 98, 208);
}

.approach-animation svg .svg-elem-15 {
  stroke-dashoffset: 506.37060546875px;
  stroke-dasharray: 506.37060546875px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.2s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.2s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5s;
}

.approach-animation.active svg .svg-elem-15 {
  stroke-dashoffset: 0;
  fill: rgb(0, 0, 0);
}

.approach-animation svg .svg-elem-16 {
  stroke-dashoffset: 121.38052083641213px;
  stroke-dasharray: 121.38052083641213px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.5s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.3s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.5s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.3s;
}

.approach-animation.active svg .svg-elem-16 {
  stroke-dashoffset: 0;
  fill: rgb(249, 114, 116);
}

.approach-animation svg .svg-elem-17 {
  stroke-dashoffset: 244px;
  stroke-dasharray: 244px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.8s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.6s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.8s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.6s;
}

.approach-animation.active svg .svg-elem-17 {
  stroke-dashoffset: 0;
  fill: rgb(73, 73, 73);
}

.approach-animation svg .svg-elem-18 {
  stroke-dashoffset: 349.9117431640625px;
  stroke-dasharray: 349.9117431640625px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.1s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.8999999999999995s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.1s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.8999999999999995s;
}

.approach-animation.active svg .svg-elem-18 {
  stroke-dashoffset: 0;
  fill: rgb(0, 0, 0);
}

.approach-animation svg .svg-elem-19 {
  stroke-dashoffset: 1838.6395263671875px;
  stroke-dasharray: 1838.6395263671875px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.3999999999999995s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 6.199999999999999s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.3999999999999995s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 6.199999999999999s;
}

.approach-animation.active svg .svg-elem-19 {
  stroke-dashoffset: 0;
  fill: rgb(0, 0, 0);
}

.approach-animation svg .svg-elem-20 {
  stroke-dashoffset: 480px;
  stroke-dasharray: 480px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.7s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 6.5s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.7s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 6.5s;
}

.approach-animation.active svg .svg-elem-20 {
  stroke-dashoffset: 0;
  fill: rgb(249, 114, 116);
}

.approach-animation svg .svg-elem-21 {
  stroke-dashoffset: 749.33740234375px;
  stroke-dasharray: 749.33740234375px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 6s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 6.8s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 6s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 6.8s;
}

.approach-animation.active svg .svg-elem-21 {
  stroke-dashoffset: 0;
  fill: rgb(0, 0, 0);
}

.approach-animation svg .svg-elem-22 {
  stroke-dashoffset: 1442px;
  stroke-dasharray: 1442px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 6.3s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 7.1s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 6.3s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 7.1s;
}

.approach-animation.active svg .svg-elem-22 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

.approach-animation svg .svg-elem-23 {
  stroke-dashoffset: 1282px;
  stroke-dasharray: 1282px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 6.6s,
                        fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 7.3999999999999995s;
          transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 6.6s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 7.3999999999999995s;
}

.approach-animation.active svg .svg-elem-23 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}
