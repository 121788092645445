.standardSection {
    height: auto;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.standardWrapper {
    /* display: inline-block; */
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    max-width: 1226px;
    position: relative;
    width: 100%;
}

.standardTitle {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 35px;
}

.standardBody {
    color: #3d3d3d;
    font-size: 22px;
    font-weight: 100;
    line-height: 1.3;
    margin-bottom: 35px;
}

.standardNavLink {
    align-items: center;
    color: white;
    cursor: pointer;
    display: flex;
    font-family: "sharp-grotesk-book";
    font-size: 15px;
    height: 100%;
    text-decoration: none;
    color: rgb(249, 114, 116);
    position: relative;
    width: max-content;
}

/* .standardNavLink::after {
    bottom: -10px;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: rgb(249, 114, 116);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
} */

.standardNavLink:hover {
    color: #00FFC2;
}

/* @media screen and (max-width: 1024px) {
    .standardTitle {
        font-size: 1.2rem;
    }
    .standardBody {
        font-size: 1.75rem;
    }
}

@media screen and (max-width: 768px) {
    .standardTitle , .standardBody {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 520px) {
    .standardTitle {
        font-size: 1rem;
    }
    .standardBody {
        font-size: 1.25rem;
    }
} */

.priorityText {
    text-align: left;
    font-size: 22px;
    font-weight: 500;
}