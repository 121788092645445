.sliderContent {
    background-color: rgba(255, 255, 255, 0.8);
    bottom: 0;
    left: 0;
    padding: 26px;
    position: absolute;
    width: 100%;
    z-index: 1;
    transition: 0.5s;
}

.sliderContent .sliderTitle {
    color: #7000FF;
    font-family: "sharp-grotesk-book", sans-serif;
    font-size: 32px;
    text-align: center;
}
.sliderContent .sliderTitle, .sliderContent .body {
    margin-bottom: 0;
    transition: 0.3s;
    transition-delay: 0.15s;
}

.sliderContent .body {
    font-size: 2rem;
    font-weight: 300;
}

@media screen and (max-width: 1024px) {
    .sliderContent .sliderTitle {
        font-size: 25px;
    }
    .sliderContent .body {
        font-size: 1.75rem;
    }
}

@media screen and (max-width: 768px) {
    .awssld__controls {
        display: none;
    }
    .sliderContent {
        padding: 20px;
        width: 100%;
    }
}

@media screen and (max-width: 520px) {
    .sliderContent .sliderTitle {
        font-size: 18px;
    }
    .sliderContent .body {
        font-size: 1.25rem;
    }
}

.awssld__wrapper {
    max-height: 641px;
}

/* .awssld__box {
    max-width: 80%;
} */

/* .awssld__box img {
    height: 250px;
    width: 100px;
} */

.awssld__box .sliderContent .sliderTitle {
    opacity: 0;
}

.awssld__box .sliderContent .body {
    opacity: 0;
}

.awssld__box .sliderContent {
    opacity: 0;
    bottom: -100px;
}

.awssld__box.awssld--active .sliderContent {
    opacity: 100;
    bottom: 0;
}

.awssld__box.awssld--active .sliderContent .sliderTitle {
    opacity: 100;
}

.awssld__box.awssld--active .sliderContent .body {
    opacity: 100;
}

.awssld__controls {
    bottom: 0;
    position: absolute;
    right: 0;
    z-index: 2;
}

.awssld__controls button {
    all: revert;
    border: none;
    height: 100px;
    position: relative;
    width: 100px;
    transition: 0.3s;
}

.awssld__controls button svg {
    height: 40px;
    width: 40px;
}

.awssld__controls button:hover {
    background-color: rgba(0,0,0,0.5);
    color: #ffffff;
}

.awssld__controls .awssld__next {
    float: right;
    margin-right: 20px;
}

.awssld__controls .awssld__prev {
    float: left;
    margin-right: 5px;
}